<template>
  <div class="doc-container container">
    <h2 class="h2_title">违规记录处罚规则与实施细则</h2>
    <h3>1.背景</h3>
    <p>为了规范平台的应用，维护开发者的合法权益，针对侵权、山寨、恶意上传等行为进行打击处理，并针对不同行为进行不同级别的处罚。</p>
    <h3>2.内容违规处罚流程</h3>
    <table class="table">
      <tbody>
        <tr>
          <th width="10%">隐私权限</th>
          <td width="80%">
            首次进入应用需要用户协议和隐私政策弹窗或者进入首页有显示，且需要用户可选择同意或拒绝操作。另外需有用户协议和隐私政策常驻入口，查询步骤不能超过4次。<br />
            同时应用必须有完整的【隐私政策】内容长图告知用户所收集信息及使用，长图只需完整的隐私内容，无需抬头带有手机状态栏信息（手机信号、wifi等），字体需清晰。
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">资质</th>
          <td width="80%">
            每个APP/小程序必须拥有软著<br />
            资质作者与认证作者不一致，需要提供合作协议或者关系证明
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">重复上传</th>
          <td width="80%">
            同一个作者同个软件著作证明上传2款或多款相同内容的应用
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">体验问题</th>
          <td width="80%">
            无法进入、黑屏、闪退等问题<br />
            应用中任何广告不能超屏幕一半且无法关闭等影响正常体验的广告问题
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">色情信息</th>
          <td width="80%">
            截图、视频含色情信息、推荐色情敏感广告、BT种子（视频链接）使用一些敏感的关键词可以搜索到大量色情淫秽视频链接
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">政治敏感</th>
          <td width="80%">
            内容明确政治或言论等违规
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">暴力恐怖</th>
          <td width="80%">
            内容血腥暴力、人身攻击、侮辱诽谤他人类
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">病毒应用</th>
          <td width="80%">
            下载之后管家提示系统危险
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">侵权</th>
          <td width="80%">
            ICON、名称、素材等侵权行为
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">举报</th>
          <td width="80%">
            超过3次因同类型问题被用户举报
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">强制推荐</th>
          <td width="80%">
            必须下载推荐的软件才可进入，积分墙广告，强制下载与本应用无关的快捷方式或者应用
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">恶意引流</th>
          <td width="80%">
            游戏内不允许除官方客服外的所有联系方式，包括但不限于：二维码、QQ群号、微信号、电话号码等
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">赌博</th>
          <td width="80%">
            游戏不得存在扎（炸）金花、21点、六合彩、德州扑克、一元购等玩法<br />
            游戏名称、内容及简介中不得出现涉嫌赌博类关键词（如彩票、博彩、赌博、赌场、六合彩、梭哈、百家乐、赢三张、轮盘、21点、奔驰、宝马、一元购等词语），不得涉及赌博内容推荐<br />
            游戏不得将虚拟货币、虚拟道具、积分、奖券反向兑换为真实货币<br />
            游戏不得将虚拟货币、虚拟道具、积分、奖券兑换为实物或大额卡券（含手机、平板电脑、价值超过1000元人民币的卡券等）<br />
            游戏不得提供用户之间互相赠送或转让虚拟货币、积分、奖券的途径<br />
            若游戏内存在随机抽取或押注竞猜玩法：a.
            不得存在以下变相赌博场景：用户投入真实货币或虚拟货币进行抽奖竞猜，且抽奖竞猜结果包含（或可直接兑换为）真实货币、实物卡券奖励；b.游戏所设置的随机抽取的相关、对应概率以及中奖结果必须对外公示<br />
            若游戏内存在对局玩法，游戏不得收取或以虚拟货币/道具/积分等方式变相收取与用户输赢相关的佣金<br />
            若游戏内存在房卡模式，游戏不得对房卡模式下的计分结果提供结算功能<br />
            游戏的竞赛与排名类运营活动，需要遵守有奖销售相关的法律法规，即时开奖中奖励超过500元（或同等价值奖券、道具）的兑奖情况要公示中奖者、奖品等信息<br />
            其他被认定为宣扬赌博色彩的游戏将被拒绝收录
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">恶意应用</th>
          <td width="80%">
            恶意刷量、恶意变更APK包内容、恶意扣费等
          </td>
          <td width="10%">删除处理</td>
        </tr>
        <tr>
          <th width="10%">资质问题</th>
          <td width="80%">
            所提供资质证明涉及造假
          </td>
          <td width="10%">删除处理</td>
        </tr>
      </tbody>
    </table>
    也同时欢迎举报至中央网信办（国家网信办）违法和不良信息举报中心：<a href="https://www.12377.cn/" target="_blank">https://www.12377.cn/ </a>
  </div>
</template>

<script>
export default {
  name: 'HelpDoc1'
}
</script>

<style lang="scss" scoped>
.doc-container {
  margin: 2rem auto !important;
  min-height: 72vh;
  // 表格
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    th,
    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }
  }
  a {
    color: #007bff;
    &:hover {
      text-decoration: underline;
    }
  }
  tr {
    border: none;
  }
  // 公共样式
  h2 {
    color: black;
  }
}
</style>
